/* eslint-disable no-console */
import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { sendErrorReport } from 'shared/helpers'
import {
  Modal,
  Notification,
  Selector,
  ContentLoader,
  Notice,
  ClipboardRow
} from 'shared/components'
import { checkLicense } from 'src/license/actions'
import { fetchLicenseApiKeys } from 'src/account/actions'
import {
  getOauthLicenseClient,
  createOauthLicenseClient,
  deleteOauthLicenseClient,
  createOauthLicenseClientSecret,
  rotateOauthLicenseClientSecret
} from 'src/license/actions'
import { useOAuth } from 'shared/hooks'

const DeviceRefreshFileForm = ({
  closeCb,
  companyID,
  licenseID,
  productShortCode,
  licenseKey,
  username,
  device
}) => {
  const { isLoading: isOAuthLoadingState, oAuthClient } = useOAuth({
    entityId: licenseID,
    companyID,
    getClient: getOauthLicenseClient,
    createClient: createOauthLicenseClient,
    deleteClient: deleteOauthLicenseClient,
    refreshSecret: createOauthLicenseClientSecret,
    rotateSecret: rotateOauthLicenseClientSecret
  })

  const isActive = get(device, 'device_active')
  const hwid = get(device, 'hardware_id')

  const [isLoading, setLoading] = useState(false)
  // license api keys
  const [isLoadingKeys, setLoadingKeys] = useState(true)
  const [licenseKeys, setLicenseKeys] = useState([])
  const [selectedApiKey, setSelectedApiKey] = useState([])

  const getLicenseAPIKeys = useCallback(() => {
    fetchLicenseApiKeys(companyID)
      .then(res => {
        const data = get(res, 'data.results') || []
        const formattedData = data
          .filter(item => !item.revoked)
          .map(item => ({ value: item.uuid, label: `${item.uuid}` }))
        setLicenseKeys(formattedData)
        setSelectedApiKey(formattedData[0].value)
        setLoadingKeys(false)
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot get license api keys')
        setLoadingKeys(false)
        Notification(
          'error',
          __('There was an error while getting your data'),
          __('Try again later')
        )
      })
  }, [companyID])

  useEffect(() => {
    getLicenseAPIKeys()
  }, [getLicenseAPIKeys])

  const getLicenseRefreshFile = () => {
    if (!isActive) {
      Notification('error', __('Device is not active'))
      return false
    }
    setLoading(hwid)
    checkLicense(
      companyID,
      hwid,
      productShortCode,
      selectedApiKey,
      licenseKey,
      encodeURIComponent(username)
    )
      .then(res => {
        const responseData = encodeURIComponent(JSON.stringify(res.data))
        const data = unescape(responseData)
        download(`data:text/plain;charset=utf-8,${btoa(data)}`, 'license_refresh.lic', 'text/plain')
        closeCb()
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot download license refresh data', { hwid })
        setLoading(false)
        Notification('error', __('Error occured'))
      })
    return true
  }

  const handleSubmit = async e => {
    e.preventDefault()
    if (isLoading) {
      return
    }

    setLoading(true)
    await getLicenseRefreshFile()
  }

  if (isLoadingKeys || isOAuthLoadingState) {
    return (
      <Modal
        closeCb={closeCb}
        confirmCb={handleSubmit}
        disabled={isLoading}
        title={__('Download license refresh file')}
        size='sm'
        hideFooter
      >
        <div>
          <ContentLoader />
        </div>
      </Modal>
    )
  }

  return (
    <Modal
      closeCb={closeCb}
      confirmCb={handleSubmit}
      disabled={isLoading}
      title={__('Download license refresh file')}
      size='sm'
    >
      {oAuthClient ? (
        <div>
          <Notice title={__('oAuth client is already initialized for the license.')}>
            {__(
              'Make sure that oAuth credentials are being used in the application running on the device.'
            )}
          </Notice>
          <p>{__('Client ID')}:</p>
          <ClipboardRow
            id='oauth-clientId'
            value={oAuthClient.clientId}
            hideLabel
            label={__('Client ID')}
          />
          <p>{__('Client secret')}:</p>
          <ClipboardRow
            id='oauth-clientId'
            value={oAuthClient.secret}
            hideLabel
            label={__('Client secret')}
          />
        </div>
      ) : (
        <div>
          <Notice title={__('Please select your API key to download license refresh file.')}>
            {__(
              'Make sure that selected api key is being used in the application running on the device.'
            )}
          </Notice>
          <Selector
            handleChange={val => setSelectedApiKey(val)}
            options={licenseKeys}
            value={selectedApiKey}
          />
        </div>
      )}
    </Modal>
  )
}

DeviceRefreshFileForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  companyID: PropTypes.number.isRequired,
  productShortCode: PropTypes.string.isRequired,
  licenseKey: PropTypes.string,
  username: PropTypes.string,
  device: PropTypes.object.isRequired
}

DeviceRefreshFileForm.defaultProps = {
  licenseKey: '',
  username: ''
}

export default DeviceRefreshFileForm
