import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { useSelector } from 'react-redux'
import {
  Button,
  ConfirmationPopup,
  DescriptionTable,
  DeviceIcon,
  IconCheckmark,
  IconDisabled,
  IconInfo,
  IconPopupOpen,
  List,
  Notification,
  StatusCard,
  Tooltip
} from 'shared/components'
import {
  getDisabledMessage,
  isFeatureAvailable,
  isFeatureEnabled,
  sendErrorReport,
  displayValue,
  formatDate
} from 'shared/helpers'
import { platformFeatures, initialDevicesListSort, defaultDateFormat } from 'shared/constants'
import {
  fetchDevices,
  resetDevice,
  revokeDevice,
  blacklistDevice,
  updateUIOptions,
  releaseBorrowedDevice
} from 'src/license/actions'
import DeviceVariablesModal from '../../DeviceVariablesModal'
import BorrowLicenseForm from '../BorrowLicenseForm'
import DeviceActionMenu from '../DeviceActionMenu'
import DeviceRefreshFileForm from '../DeviceRefreshFileForm'
import {
  getOauthLicenseClient,
  createOauthLicenseClient,
  deleteOauthLicenseClient,
  createOauthLicenseClientSecret,
  rotateOauthLicenseClientSecret
} from 'src/license/actions'
import { useOAuth } from 'shared/hooks'
import './styles.scss'

const DevicesList = ({
  companyID,
  license,
  uiOptions,
  refetchLicense,
  updateUserPreferences,
  userPrefsId,
  refetchBlacklistedDevices,
  shouldUpdateList
}) => {
  const companyDetails = useSelector(state => get(state, 'company.details'))
  const isOauthRequired = get(companyDetails, 'is_oauth_required')
  const licenseID = get(license, 'id')
  const productID = get(license, 'product.id')
  const products = useSelector(state => get(state, 'products.list'))
  const productDetails = products.find(p => p.id === productID)
  const productShortCode = get(productDetails, 'short_code')
  const licenseKey = get(license, 'license_key') || null
  const username = get(license, 'license_users[0].true_email')
  const canBorrow = get(license, 'can_borrow')

  const [isLoading, setLoading] = useState(true)
  const [confirmationLoading, setConfirmationLoading] = useState(false)
  const [devices, setDevices] = useState({})
  const [licenseRefreshDevice, setLicenseRefreshDevice] = useState(null)
  const [currentDevicesSort, setCurrentDevicesSort] = useState(
    get(uiOptions, 'list_sort.devices') || initialDevicesListSort
  )
  const [devicesPage, setDevicesPage] = useState(0)
  const [tableExpanded, setTableExpanded] = useState({})
  const [deviceToRevoke, setDeviceToRevoke] = useState(null)
  const [isDeviceRevokeConfirmationDisplayed, setDeviceRevokeConfirmationDisplay] = useState(false)
  const [deviceToResetId, setDeviceToResetId] = useState(null)
  const [isDeviceResetConfirmationDisplayed, setDeviceResetConfirmationDisplay] = useState(false)
  const [deviceToBlacklist, setDeviceToBlacklist] = useState(null)
  const [isDeviceBlacklistConfirmationDisplayed, setDeviceBlacklistConfirmationDisplay] = useState(
    false
  )
  const [deviceVariablesModalData, setDeviceVariabledModalData] = useState(null)
  const [showDeviceVariablesModal, setDeviceVariablesModalDisplay] = useState(false)
  const [toManageBorrowDevice, setToManageBorrowDevice] = useState(null)
  const [isManageBorrowedConfirmationDisplayed, setManageBorrowedConfirmationDisplay] = useState(
    false
  )
  const [isReturnBorrowedConfirmationDisplayed, setReturnBorrowedConfirmationDisplay] = useState(
    false
  )
  const [borrowingLoading, setBorrowingLoading] = useState(false)
  const { oAuthClient } = useOAuth({
    entityId: licenseID,
    companyID,
    getClient: getOauthLicenseClient,
    createClient: createOauthLicenseClient,
    deleteClient: deleteOauthLicenseClient,
    refreshSecret: createOauthLicenseClientSecret,
    rotateSecret: rotateOauthLicenseClientSecret
  })

  const getDevices = useCallback(
    (page, sort) => {
      setLoading(true)
      fetchDevices(companyID, licenseID, page, sort)
        .then(res => {
          setDevices(get(res, 'data'))
          setLoading(false)
        })
        .catch(err => {
          sendErrorReport(err, 'Cannot fetch license devices')
          Notification('error', __('There was an error while getting your data'))
          setLoading(false)
        })
    },
    [companyID, licenseID]
  )

  useEffect(() => {
    getDevices(devicesPage, currentDevicesSort)
  }, [devicesPage, currentDevicesSort, shouldUpdateList])

  const handleDeviceResetClick = () => {
    setConfirmationLoading(true)

    resetDevice(deviceToResetId, companyID)
      .then(() => {
        getDevices(devicesPage, currentDevicesSort)
        refetchLicense()
        setDeviceToResetId(null)
        setConfirmationLoading(false)
        setDeviceResetConfirmationDisplay(false)
        Notification(
          'success',
          __('Changes saved successfully'),
          __('Your license device has been reset')
        )
      })
      .catch(err => {
        setConfirmationLoading(false)
        sendErrorReport(err, 'Cannot reset license device')
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        )
      })
  }

  const handleDeviceRevoke = () => {
    const deviceID = get(deviceToRevoke, 'id')
    setConfirmationLoading(true)

    revokeDevice(deviceID, companyID)
      .then(() => {
        getDevices(devicesPage, currentDevicesSort)
        setDeviceToRevoke(null)
        setConfirmationLoading(false)
        setDeviceRevokeConfirmationDisplay(false)
        Notification(
          'success',
          __('Changes saved successfully'),
          __('License device has been revoked')
        )
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot revoke license device')
        setConfirmationLoading(false)
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        )
      })
  }

  const handleDeviceBlacklist = () => {
    const deviceID = get(deviceToBlacklist, 'id')
    setConfirmationLoading(true)

    blacklistDevice(deviceID, companyID)
      .then(() => {
        getDevices(devicesPage, currentDevicesSort)
        refetchBlacklistedDevices()
        refetchLicense()
        setDeviceToBlacklist(null)
        setConfirmationLoading(false)
        setDeviceBlacklistConfirmationDisplay(false)
        Notification(
          'success',
          __('Changes saved successfully'),
          __('License device has been blacklisted')
        )
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot blacklist license device')
        setConfirmationLoading(false)
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        )
      })
  }

  const returnBorrowedLicense = () => {
    const deviceID = get(toManageBorrowDevice, 'id')
    setBorrowingLoading(true)

    releaseBorrowedDevice(deviceID, companyID, null)
      .then(() => {
        getDevices(devicesPage, currentDevicesSort)
        setToManageBorrowDevice(null)
        setBorrowingLoading(false)
        setReturnBorrowedConfirmationDisplay(false)
        Notification(
          'success',
          __('Changes saved successfully'),
          __('License device has been returned')
        )
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot revoke license device')
        setBorrowingLoading(false)
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        )
      })
  }

  const handleDeviceRevokeForm = device => {
    const isInUse = get(device, 'floating_in_use')
    if (!isInUse) {
      Notification(
        'error',
        __('Device is not in use'),
        __('Only floating devices which are in use can be revoked')
      )
      return false
    }
    setDeviceToRevoke(device)
    setDeviceRevokeConfirmationDisplay(true)
    return true
  }

  const handleDeviceResetForm = device => {
    setDeviceToResetId(get(device, 'id'))
    setDeviceResetConfirmationDisplay(true)
  }

  const handleDeviceBlacklistForm = device => {
    setDeviceToBlacklist(device)
    setDeviceBlacklistConfirmationDisplay(true)
  }

  const handleManageBorrowForm = device => {
    if (!canBorrow) {
      Notification('error', __('License does not allow borrowing'))
      return false
    }

    setToManageBorrowDevice(device)
    setManageBorrowedConfirmationDisplay(true)
    return true
  }

  const handleReturnBorrowForm = device => {
    if (!canBorrow) {
      Notification('error', __('License does not allow borrowing'))
      return false
    }

    setToManageBorrowDevice(device)
    setReturnBorrowedConfirmationDisplay(true)
    return true
  }

  const confirmEditBorrowed = () => {
    setToManageBorrowDevice(null)
    setManageBorrowedConfirmationDisplay(false)
    getDevices(devicesPage, currentDevicesSort)
  }

  const patchUiFeatures = (newSorted = undefined) => {
    if (!userPrefsId) {
      return false
    }

    const listSort = get(uiOptions, 'list_sort') || {}
    listSort.devices = newSorted
    const ui = { ...uiOptions, list_sort: listSort }
    const data = { ui_options: JSON.stringify(ui) }

    updateUIOptions(userPrefsId, companyID, data)
      .then(res => updateUserPreferences(res.data))
      .catch(err => sendErrorReport(err, 'Cannot update UI options', data))
    return true
  }

  const handleLicenseRefreshClick = row => {
    if (isOauthRequired && !oAuthClient) {
      Notification(
        'error',
        __('oAuth client is required'),
        __('Please initialize oAuth client before downloading the license refresh file.')
      )
      return
    }

    setLicenseRefreshDevice(get(row, 'original'))
  }

  return (
    <div className='DevicesList devices-list'>
      <List
        onExpandedChange={expanded => setTableExpanded(expanded)}
        expanded={tableExpanded}
        SubComponent={row => {
          const hasUsers =
            get(row, 'original.license_users') && get(row, 'original.license_users').length > 0
          const isBorrowed = get(row, 'original.borrowed_until')
          return (
            <div className='SubComponent'>
              <div className='SubComponent-actions'>
                <Button
                  featureEnabled={isFeatureEnabled(platformFeatures.check_offline)}
                  featureAvailable={isFeatureAvailable(platformFeatures.check_offline)}
                  notEnabledMessage={getDisabledMessage()}
                  size='sm'
                  onClick={() => handleLicenseRefreshClick(row)}
                >
                  {__('Download license refresh file')}
                </Button>
              </div>
              <DescriptionTable
                details={[
                  {
                    label: isBorrowed ? __('Borrowed until') : null,
                    value: formatDate(get(row, 'original.borrowed_until'), defaultDateFormat)
                  },
                  {
                    label: __('First activated'),
                    value: formatDate(get(row, 'original.time_activated'), defaultDateFormat)
                  },
                  {
                    label: __('OS'),
                    value: displayValue(get(row, 'original.os'))
                  },
                  {
                    label: __('SDK build'),
                    value: displayValue(get(row, 'original.sdk_build_version'))
                  },
                  {
                    label: __('App version'),
                    value: displayValue(get(row, 'original.app_ver'))
                  },
                  {
                    label: __('Hostname'),
                    value: displayValue(get(row, 'original.hostname'))
                  },
                  {
                    label: __('External IP address'),
                    value: displayValue(get(row, 'original.external_ip'))
                  },
                  {
                    label: __('IP address'),
                    value: displayValue(get(row, 'original.ip'))
                  },
                  {
                    label: __('MAC address'),
                    value: displayValue(get(row, 'original.mac_address'))
                  },
                  {
                    label: __('Is virtual machine'),
                    value: get(row, 'original.is_vm') ? __('Yes') : __('No')
                  },
                  {
                    label: __('VM info'),
                    value: displayValue(get(row, 'original.vm_info'))
                  }
                ]}
              />
              {hasUsers && (
                <div className='SubComponent-users'>
                  <div className='heading'>{__('Users')}</div>
                  <ul className='list'>
                    {get(row, 'original.license_users').map(u => (
                      <li key={u.id}>{u.true_email}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          )
        }}
        columns={[
          {
            expander: true,
            Header: __('Details'),
            headerClassName: 'text-center',
            width: 80,
            style: {
              fontSize: 25,
              padding: '0',
              textAlign: 'center',
              userSelect: 'none'
            }
          },
          {
            accessor: 'type',
            Header: __('Type'),
            className: 'text-center',
            headerClassName: 'text-center',
            width: 70,
            Cell: cellInfo => (
              <div className='device-type-icon'>
                <DeviceIcon device={cellInfo.value} />
              </div>
            )
          },
          {
            accessor: 'hardware_id',
            Header: __('Hardware ID'),
            Cell: cellInfo => displayValue(cellInfo.value)
          },
          {
            accessor: 'last_check',
            Header: __('Last checked'),
            Cell: cellInfo => formatDate(cellInfo.value),
            width: 140
          },
          {
            accessor: 'device_active',
            Header: __('Status'),
            headerClassName: 'text-center',
            className: 'text-center',
            width: 100,
            Cell: cellInfo => (
              <StatusCard
                status={cellInfo.value ? 'success' : 'error'}
                text={cellInfo.value ? `${__('Active')}` : `${__('Inactive')}`}
              />
            ),
            maxWidth: 100
          },
          {
            accessor: 'floating_in_use',
            headerClassName: 'text-center',
            className: 'text-center',
            Header: __('In use'),
            width: 80,
            Cell: cellData =>
              cellData.value ? (
                <IconCheckmark color='#10ac84' height='14px' />
              ) : (
                <IconDisabled color='red' height='14px' />
              ),
            show: get(license, 'is_floating_cloud') || get(license, 'is_floating')
          },
          {
            id: 'is_borrowed',
            headerClassName: 'text-center',
            className: 'text-center',
            Header: __('Is borrowed'),
            width: 120,
            Cell: cellData => {
              const isBorrowed = get(cellData, 'original.borrowed_until') || ''
              return isBorrowed ? (
                <IconCheckmark color='#10ac84' height='14px' />
              ) : (
                <IconDisabled color='red' height='14px' />
              )
            },
            show: get(license, 'is_floating_cloud') || get(license, 'is_floating')
          },
          {
            accessor: 'floating_last_seen',
            headerClassName: 'text-center',
            className: 'text-center',
            Header: __('Floating last check-in'),
            Cell: cellInfo => formatDate(cellInfo.value),
            width: 140,
            show: get(license, 'is_floating_cloud') || get(license, 'is_floating')
          },
          {
            Header: () => (
              <div className='Device-variables-header'>
                {__('Device variables')}
                <Tooltip
                  content={__(
                    'Collect data from within your application and send it back to the LicenseSpring cloud, such as fields from a customer registration form, error codes, or system information that our SDK is not collecting by default.'
                  )}
                  active
                >
                  <span>
                    <IconInfo height='12px' width='12px' />
                  </span>
                </Tooltip>
              </div>
            ),
            accessor: 'device_variables',
            width: 150,
            className: 'text-center',
            headerClassName: 'text-center',
            sortable: false,
            Cell: cellInfo => (
              <Button
                className='table-button'
                featureEnabled={isFeatureEnabled(platformFeatures.extra_device_variables)}
                notEnabledMessage={getDisabledMessage()}
                featureAvailable={isFeatureAvailable(platformFeatures.extra_device_variables)}
                notAvailableMessage='Device variables are unavailable in your current plan.'
                ctaText={__(
                  'Contact sales to upgrade to a plan that supports device custom variables.'
                )}
                onClick={() => {
                  setDeviceVariabledModalData(cellInfo.value)
                  setDeviceVariablesModalDisplay(true)
                }}
                type='button'
              >
                <IconPopupOpen
                  fill='none'
                  stroke='#000'
                  strokeWidth='2'
                  width='18'
                  height='18'
                  viewBox='0 0 24 24'
                />
              </Button>
            ),
            maxWidth: 150
          },
          {
            id: 'actions',
            className: 'select-container action-menu',
            Header: __('Actions'),
            headerClassName: 'text-center',
            Cell: rowData => (
              <DeviceActionMenu
                license={license}
                device={get(rowData, 'original')}
                handleDeviceRevokeForm={handleDeviceRevokeForm}
                handleDeviceResetForm={handleDeviceResetForm}
                handleDeviceBlacklistForm={handleDeviceBlacklistForm}
                handleManageBorrow={handleManageBorrowForm}
                handleReturnBorrow={handleReturnBorrowForm}
              />
            ),
            width: 70
          }
        ]}
        data={get(devices, 'results', [])}
        loading={isLoading}
        clickable={false}
        manual
        page={devicesPage}
        pages={Math.ceil(devices.count / 20)}
        minRows={2}
        showPagination
        onPageChange={page => setDevicesPage(page)}
        onSortedChange={newSorted => {
          setCurrentDevicesSort(newSorted)
          patchUiFeatures(newSorted)
        }}
        defaultSorted={currentDevicesSort}
      />
      {showDeviceVariablesModal && (
        <DeviceVariablesModal
          close={() => {
            setDeviceVariabledModalData(null)
            setDeviceVariablesModalDisplay(false)
          }}
          data={deviceVariablesModalData}
        />
      )}
      {isDeviceResetConfirmationDisplayed && (
        <ConfirmationPopup
          closeCb={() => {
            setDeviceToResetId(null)
            setDeviceResetConfirmationDisplay(false)
          }}
          confirmCb={handleDeviceResetClick}
          title={__('Are you sure you want to reset this device?')}
          confirmText={__('Reset')}
          theme='warning'
          disabled={confirmationLoading}
        />
      )}
      {isDeviceRevokeConfirmationDisplayed && (
        <ConfirmationPopup
          closeCb={() => {
            setDeviceToRevoke(null)
            setDeviceRevokeConfirmationDisplay(false)
          }}
          confirmCb={handleDeviceRevoke}
          title={__('Are you sure you want to revoke this device?')}
          confirmText={__('Revoke')}
          theme='error'
          disabled={confirmationLoading}
        />
      )}
      {isDeviceBlacklistConfirmationDisplayed && (
        <ConfirmationPopup
          closeCb={() => {
            setDeviceToBlacklist(null)
            setDeviceBlacklistConfirmationDisplay(false)
          }}
          confirmCb={handleDeviceBlacklist}
          title={__('Are you sure you want to blacklist this device?')}
          confirmText={__('Blacklist')}
          theme='error'
          disabled={confirmationLoading}
        />
      )}
      {isReturnBorrowedConfirmationDisplayed && (
        <ConfirmationPopup
          closeCb={() => {
            setToManageBorrowDevice(null)
            setReturnBorrowedConfirmationDisplay(false)
          }}
          confirmCb={returnBorrowedLicense}
          title={__('Are you sure you want to return this borrowed license?')}
          confirmText={__('Return')}
          theme='warning'
          disabled={borrowingLoading}
        />
      )}
      {isManageBorrowedConfirmationDisplayed && (
        <BorrowLicenseForm
          closeCb={() => {
            setToManageBorrowDevice(null)
            setManageBorrowedConfirmationDisplay(false)
          }}
          confirmCb={confirmEditBorrowed}
          device={toManageBorrowDevice}
          license={license}
          companyID={companyID}
        />
      )}
      {licenseRefreshDevice && (
        <DeviceRefreshFileForm
          closeCb={() => {
            setLicenseRefreshDevice(null)
          }}
          companyID={companyID}
          licenseID={licenseID}
          productShortCode={productShortCode}
          licenseKey={licenseKey}
          username={username}
          device={licenseRefreshDevice}
        />
      )}
    </div>
  )
}

DevicesList.propTypes = {
  companyID: PropTypes.number.isRequired,
  license: PropTypes.object.isRequired,
  uiOptions: PropTypes.object.isRequired,
  refetchLicense: PropTypes.func.isRequired,
  userPrefsId: PropTypes.number,
  updateUserPreferences: PropTypes.func.isRequired,
  refetchBlacklistedDevices: PropTypes.func.isRequired,
  shouldUpdateList: PropTypes.number.isRequired
}

DevicesList.defaultProps = {
  userPrefsId: null
}

export default DevicesList
